import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Paper,
  Box,
  Fab,
} from "@mui/material";
import moment from "moment";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import http from "../../http";
import { styled } from "@mui/material/styles";
import Herologo from "../home/HeroLogo";
import { DateTime } from "luxon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CardComponent = ({
  userDetail,
  emailExits,
  selectedStartDate,
  selectedEndDate,
  selectedPackage,
  extraItem,
  selectDayItem,
  selectedStand,
  userId,
  newsOffer,
  couponDetail,
  isCouponApplied,
  isReferralCodeApplied,
  referralCode,
  referralDetail,
}) => {
  const [finalP, setFinalPrice] = useState(0);
  const [success, setSuccess] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [orderConfirm, setOrderConfirm] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let finalPrice = 0;
  // creates a paypal order

  const getFinalPrice = (finalPrice) => {
    const originalPrice = parseFloat(finalPrice);
    let priceAfterCoupon = originalPrice;
    if (
      Object.keys(couponDetail).length > 0 &&
      couponDetail.discount_amount > 0
    ) {
      const couponDiscount =
        couponDetail.discount_type === "fixed"
          ? couponDetail.discount_amount
          : (originalPrice - Number(extraItem?.price ? extraItem.price : 0)) *
            (couponDetail.discount_amount / 100);
      priceAfterCoupon -= couponDiscount;
    }

    let finalPriceAfterAllDiscounts = priceAfterCoupon;
    if (
      Object.keys(referralDetail).length > 0 &&
      referralDetail.discount_amount > 0
    ) {
      const referralDiscount =
        referralDetail.discount_type === "fixed"
          ? referralDetail.discount_amount
          : priceAfterCoupon * (referralDetail.discount_amount / 100);
      finalPriceAfterAllDiscounts -= referralDiscount;
    }

    finalPriceAfterAllDiscounts = Math.max(finalPriceAfterAllDiscounts, 0);
    return finalPriceAfterAllDiscounts.toFixed(2);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const createOrder = async (data, actions) => {
    if (
      monthNames[selectedStartDate.getMonth()] == "September" ||
      monthNames[selectedStartDate.getMonth()] == "October"
    ) {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0);
      setFinalPrice(
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice
      );
    } else {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0);
      setFinalPrice(
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice
      );
    }

    return actions?.order
      .create({
        purchase_units: [
          {
            description: "Booking stand " + selectDayItem?.title,
            amount: {
              currency_code: "EUR",
              value:
                isCouponApplied || isReferralCodeApplied
                  ? getFinalPrice(finalPrice)
                  : parseFloat(finalPrice),
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then(async (orderID) => {
        // const emailReponse = await http.request(config);

        // if (
        //   !emailReponse ||
        //   (emailReponse.status !== 200 &&
        //     emailReponse.data.message !== 'Email sent successfully!')
        // ) {
        //   console.error('Confirmation email could not be sent!');
        //   alert('Confirmation email could not be sent!');
        //   return;
        // }
        return orderID;
      });
  };

  const generateRandomPassword = (length) => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    return window.crypto
      .getRandomValues(new Uint8Array(length))
      .reduce((password, number) => {
        return password + charset.charAt(number % charset?.length);
      }, "");
  };

  // check Approval
  const onApprove = async (data, actions) => {
    return actions?.order.capture().then(function (details) {
      console.log("Calling place order");
      const paypalCode =
        details?.purchase_units?.[0]?.payments?.captures?.[0]?.id;
      placeOrder(paypalCode);
      sendEmail(false);
    });
  };

  const sendEmail = async (isPlaceOrder) => {
    let bookingData = {
      bookingStand: JSON.stringify(selectDayItem?.title),
      userDetails: JSON.stringify(userDetail),
      selectedStartDate: JSON.stringify(selectedStartDate),
      selectedEndDate: JSON.stringify(selectedEndDate),
      selectedPackage: JSON.stringify(selectedPackage),
      extraItem: JSON.stringify(extraItem),
      selectedDayItem: JSON.stringify(selectDayItem),
      selectedStand: JSON.stringify(selectedStand),
      newsOffer: JSON.stringify(newsOffer),
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/booking/email",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify(bookingData),
    };

    const emailReponse = await http.request(config);

    if (
      !emailReponse ||
      (emailReponse.status !== 200 &&
        emailReponse.data.message !== "Email sent successfully!")
    ) {
      console.error("Confirmation email could not be sent!");
      alert("Confirmation email could not be sent!");
      return;
    }
    if (isPlaceOrder) {
      placeOrder();
    }
  };

  const placeOrder = async (paypalCode) => {
    if (
      monthNames[selectedStartDate.getMonth()] == "September" ||
      monthNames[selectedStartDate.getMonth()] == "October"
    ) {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0);
      setFinalPrice(
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice
      );
    } else {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0);
      setFinalPrice(
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice
      );
    }
    console.log(
      "------------------------------------------------------------------"
    );
    if (!emailExits) {
      const passwordString = generateRandomPassword(10);
      let data = {
        first_name: userDetail?.firstName,
        last_name: userDetail?.lastName,
        email: userDetail?.email,
        status: "active",
        password: `${passwordString}`,
        confirm_password: `${passwordString}`,
        country: userDetail?.country,
        zip: userDetail?.zip,
        iban: userDetail?.iban,
        address: userDetail?.address,
        phone_number: userDetail?.phoneNo,
        news_offer: newsOffer,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/auth/register",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
      };
      http
        .request(config)
        .then((response) => {
          const { data } = response;
          if (data?.status) {
            let bookingData = JSON.stringify({
              shelf_type: 1,
              stand_id: selectedStand?.id,
              paypal_code: paypalCode,
              duration: selectDayItem?.duration,
              start: selectedStartDate,
              end: selectedEndDate,
              collect_product_by: extraItem?.title,
              rebook: 0,
              rent_price:
                isCouponApplied || isReferralCodeApplied
                  ? getFinalPrice(finalPrice)
                  : parseFloat(finalPrice),
              original_price:
                isCouponApplied || isReferralCodeApplied
                  ? getFinalPrice(finalPrice)
                  : parseFloat(finalPrice),
              status: "active",
              prepare: moment(selectedStartDate)
                .locale("en")
                .subtract(1, "days")
                .format("LL"),
              user_id: data?.data.id,
              description: " ",
              coupon: isCouponApplied ? couponDetail?.code : "null",
              referral_code: isReferralCodeApplied ? referralCode : null,
            });
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "/booking/place_order?sendEmail=false",
              headers: {
                "Content-Type": "application/json",
              },
              data: bookingData,
            };
            http
              .request(config)
              .then((response) => {
                const { data } = response?.data;
                localStorage.setItem("bookingId", data?.id);
                setBookingId(data?.id);
                setSuccess(true);
                setOrderConfirm(true);
                localStorage.removeItem("price");
                localStorage.removeItem("deliverBy");
              })
              .catch(function (error) {
                localStorage.removeItem("price");
                localStorage.removeItem("deliverBy");
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  alert(error.response.data.message);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser
                  // and an instance of http.ClientRequest in node.js
                  console.log(error.request);
                  alert(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                  alert(error.message);
                }
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let bookingData = JSON.stringify({
        shelf_type: 1,
        stand_id: selectedStand?.id,
        duration: selectDayItem?.duration,
        start: selectedStartDate,
        end: selectedEndDate,
        paypal_code: paypalCode,
        collect_product_by: extraItem?.title,
        rent_price:
          isCouponApplied || isReferralCodeApplied
            ? getFinalPrice(finalPrice)
            : parseFloat(finalPrice),
        original_price:
          isCouponApplied || isReferralCodeApplied
            ? getFinalPrice(finalPrice)
            : parseFloat(finalPrice),
        status: "active",
        prepare: moment(selectedStartDate)
          .locale("en")
          .subtract(1, "days")
          .format("LL"),
        user_id: userId,
        description: " ",
        coupon: isCouponApplied ? couponDetail?.code : "null",
        referral_code: referralCode,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/booking/place_order",
        headers: {
          "Content-Type": "application/json",
        },
        data: bookingData,
      };

      http
        .request(config)
        .then((response) => {
          const { data } = response?.data;
          localStorage.setItem("bookingId", data?.id);
          setBookingId(data?.id);
          setSuccess(true);
          setOrderConfirm(true);
          localStorage.removeItem("price");
          localStorage.removeItem("deliverBy");
        })
        .catch(function (error) {
          localStorage.removeItem("price");
          localStorage.removeItem("deliverBy");
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            alert(error.response.data.message);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            // and an instance of http.ClientRequest in node.js
            console.log(error.request);
            alert(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            alert(error.message);
          }
        });
    }
  };
  //capture likely err
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    let price = 0;
    if (
      monthNames[selectedStartDate.getMonth()] == "September" ||
      monthNames[selectedStartDate.getMonth()] == "October"
    ) {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.discountPrice) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.discountPrice) +
            Number(extraItem?.price ? extraItem?.price : 0);
      price =
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice;
      setShowButton(price == 0 ? true : false);
    } else {
      finalPrice =
        selectedPackage.id === 5
          ? Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0) +
            0
          : Number(selectDayItem?.price) +
            Number(extraItem?.price ? extraItem?.price : 0);
      price =
        isCouponApplied || isReferralCodeApplied
          ? getFinalPrice(finalPrice)
          : finalPrice;
      setShowButton(price == 0 ? true : false);
    }

    // if (!isCouponApplied) {
    //   if (!emailExits) {
    //     const passwordString = generateRandomPassword(10);
    //     let data = {
    //       "first_name": userDetail?.firstName,
    //       "last_name": userDetail?.lastName,
    //       "email": userDetail?.email,
    //       "status": 'active',
    //       "password": `${passwordString}`,
    //       "confirm_password": `${passwordString}`,
    //       "country": userDetail?.country,
    //       "zip": userDetail?.zip,
    //       "iban": userDetail?.iban,
    //       "address": userDetail?.address,
    //       "phone_number": userDetail?.phoneNo
    //     };

    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       url: '/auth/register',
    //       headers: { 'Content-Type': 'application/json' },
    //       data: JSON.stringify(data)
    //     };
    //     http.request(config)
    //       .then((response) => {
    //         const { data } = response;
    //         if (data?.status) {
    //           console.log("🚀 ~ file: Section.js:341 ~ .then ~ data:", data)
    //           let bookingData = {
    //             "shelf_type": 1,
    //             "stand_id": selectedStand?.id,
    //             "duration": selectDayItem?.duration,
    //             "start": selectedStartDate,
    //             "end": selectedEndDate,
    //             "collect_product_by": extraItem?.title,
    //             "rebook": 0,
    //             "rent_price": Number(price).toFixed(2),
    //             "original_price": Number(price).toFixed(2),
    //             "status": "Due Payment",
    //             "prepare": moment(selectedStartDate).subtract(1, 'days').format('LL'),
    //             "user_id": data?.data.id,
    //             "description": " ",
    //           };
    //           if (isCouponApplied) {
    //             bookingData["coupon"] = couponDetail?.code;
    //           }
    //           let config = {
    //             method: 'post',
    //             maxBodyLength: Infinity,
    //             url: '/booking/place_order',
    //             headers: {
    //               'Content-Type': 'application/json'
    //             },
    //             data: JSON.stringify(bookingData)
    //           };
    //           console.log("🚀 ~ file: Section.js:366 ~ .then ~ config:", config)
    //           http.request(config)
    //             .then((response) => {
    //               console.log("🚀 ~ file: SecondCard.js:327 ~ .then ~ response:", response)
    //               const { data } = response?.data;
    //               localStorage.setItem('bookingId',data?.id)
    //               setBookingId(data?.id);
    //               // setSuccess(true);
    //               // setOrderConfirm(true)
    //               // localStorage.removeItem('deliverBy')

    //             }).catch(function (error) {
    //               localStorage.removeItem('price')
    //               localStorage.removeItem('deliverBy')
    //               localStorage.removeItem('bookingId')

    //               if (error.response) {
    //                 alert(error.response.data.message);
    //                 window.location.reload();
    //               } else if (error.request) {
    //                 alert(error.request);
    //                 window.location.reload();
    //               } else {
    //                 alert(error.message);
    //                 window.location.reload();
    //               }
    //             });
    //         }
    //       }).catch(function (error) {
    //         localStorage.removeItem('price')
    //         localStorage.removeItem('deliverBy')
    //         localStorage.removeItem('bookingId')
    //         if (error.response) {
    //           alert(error.response.data.message);
    //           window.location.reload();
    //         } else if (error.request) {
    //           alert(error.request);
    //           window.location.reload();
    //         } else {
    //           alert(error.message);
    //           window.location.reload();
    //         }
    //       });
    //   } else {
    //     let bookingData = {
    //       "shelf_type": 1,
    //       "stand_id": selectedStand?.id,
    //       "duration": selectDayItem?.duration,
    //       "start": selectedStartDate,
    //       "end": selectedEndDate,
    //       "collect_product_by": extraItem?.title,
    //       "rebook": 0,
    //       "rent_price": Number(price).toFixed(2),
    //       "original_price": Number(price).toFixed(2),
    //       "status": "Due Payment",
    //       "prepare": moment(selectedStartDate).subtract(1, 'days').format('LL'),
    //       "user_id": userId,
    //       "description": " ",
    //     };
    //     if (isCouponApplied) {
    //       bookingData["coupon"] = couponDetail?.code;
    //     }
    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       url: '/booking/place_order',
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       data: JSON.stringify(bookingData)
    //     };
    //     console.log("🚀 ~ file: Section.js:366 ~ .then ~ config:", config)
    //     http.request(config)
    //       .then((response) => {
    //         console.log(JSON.stringify(response.data));
    //         const { data } = response?.data;
    //         localStorage.setItem('bookingId',data?.id)
    //         setBookingId(data?.id);
    //         // setOrderConfirm(true)
    //         // localStorage.removeItem('price')
    //         // localStorage.removeItem('deliverBy')
    //       }).catch(function (error) {
    //         localStorage.removeItem('price')
    //         localStorage.removeItem('deliverBy')
    //         localStorage.removeItem('bookingId')
    //         if (error.response) {
    //           alert(error.response.data.message);
    //           window.location.reload();
    //         } else if (error.request) {
    //           alert(error.request);
    //           window.location.reload();
    //         } else {
    //           alert(error.message);
    //           window.location.reload();
    //         }
    //       });
    //   }
    // }
  }, []);

  const handleClick = async () => {
    await placeOrder();
  };

  console.log(
    getFinalPrice(
      `${
        monthNames[selectedStartDate.getMonth()] === "September" ||
        monthNames[selectedStartDate.getMonth()] === "October"
          ? selectedPackage?.id === 5
            ? `${Number(selectDayItem?.price ? selectDayItem.price : 0)
                .toFixed(2)
                .replace(".", ",")} € + 40 % Verkaufsprovision`
            : `${Number(selectDayItem?.price ? selectDayItem.price : 0)
                .toFixed(2)
                .replace(".", ",")} \u20AC`
          : selectedPackage?.id === 5
          ? `${
              Number(selectDayItem?.price ? selectDayItem.price : 0)
                .toFixed(2)
                .replace(".", ",") +
              Number(extraItem?.price ? extraItem.price : 0)
                .toFixed(2)
                .replace(".", ",")
            } € + 40 % Verkaufsprovision`
          : `${
              Number(selectDayItem?.price ? selectDayItem.price : 0)
                .toFixed(2)
                .replace(".", ",") +
              Number(extraItem?.price ? extraItem.price : 0)
                .toFixed(2)
                .replace(".", ",")
            } \u20AC`
      }`
    ).toString() === "0.00"
  );

  return (
    <Grid
      container
      spacing={3}
      style={{ width: "100%", margin: "auto" }}
      justifyContent={"center"}
    >
      {orderConfirm ? (
        <div>
          <div>
            <Herologo />
            <div>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "25px",
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                Vielen Dank für deine Buchung!
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                Weitere Details zu deiner Buchung findest du in deiner
                Bestellbestätigung.
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                  marginTop: 5,
                }}
              >
                Wir freuen uns auch dich!
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{ height: "100%", width: "100%", borderRadius: "10px", p: 3 }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  sx={{ fontWeight: 600, marginBottom: 5 }}
                >
                  Deine Daten
                </Typography>
                <ul style={{ paddingLeft: "6px" }}>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    {`${capitalizeFirstLetter(
                      userDetail?.firstName
                    )} ${capitalizeFirstLetter(userDetail?.lastName)}`}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    {`${capitalizeFirstLetter(userDetail?.address)}`}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    {`${capitalizeFirstLetter(
                      userDetail?.country
                    )}, ${capitalizeFirstLetter(userDetail?.zip)}`}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    {`${userDetail?.email}`}
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card
              sx={{ height: "100%", width: "100%", borderRadius: "10px", p: 3 }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  sx={{ fontWeight: 600, marginBottom: 5 }}
                >
                  Details zu deiner Buchung
                </Typography>
                <ul style={{ paddingLeft: "6px" }}>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    Mietbeginn:{" "}
                    {DateTime.fromJSDate(new Date(selectedStartDate))
                      .setLocale("de")
                      .toFormat("dd. LLLL yyyy")}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    Einräumen: frühestens am Werktag vor deinem Mietbeginn eine
                    Stunde vor Ladenschluss und täglich während der
                    Öffnungszeiten ab 10:00 Uhr
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    {" "}
                    Mietende:{" "}
                    {DateTime.fromJSDate(new Date(selectedEndDate))
                      .setLocale("de")
                      .toFormat("dd. LLLL yyyy")}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    Ausräumen: bis spätestens{" "}
                    {DateTime.fromJSDate(new Date(selectedEndDate))
                      .setLocale("de")
                      .toFormat("dd. LLLL yyyy")}{" "}
                    eine Stunde vor Ladenschluss
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    Paket: {`${extraItem?.title}`}
                  </li>

                  <li style={{ paddingBottom: "20px" }}>
                    Regalmiete:{" "}
                    {`${
                      monthNames[selectedStartDate.getMonth()] ===
                        "September" ||
                      monthNames[selectedStartDate.getMonth()] === "October"
                        ? selectedPackage?.id === 5
                          ? `${Number(selectDayItem?.price)
                              .toFixed(2)
                              .replace(".", ",")} € + 40 % Verkaufsprovision`
                          : `${Number(selectDayItem?.price)} \u20AC`
                        : selectedPackage?.id === 5
                        ? `${Number(selectDayItem?.price)
                            .toFixed(2)
                            .replace(".", ",")} € + 40 % Verkaufsprovision`
                        : `${Number(selectDayItem?.price)} \u20AC`
                    }`}
                  </li>

                  <li style={{ paddingBottom: "20px" }}>
                    Extra-Service:{" "}
                    {`${
                      extraItem?.price
                        ? extraItem?.price.toFixed(2).replace(".", ",")
                        : "0,00"
                    } \u20AC`}
                  </li>
                  <li style={{ paddingBottom: "20px" }}>
                    Gesamtpreis:{" "}
                    {isCouponApplied || isReferralCodeApplied
                      ? getFinalPrice(
                          `${
                            monthNames[selectedStartDate.getMonth()] ===
                              "September" ||
                            monthNames[selectedStartDate.getMonth()] ===
                              "October"
                              ? selectedPackage?.id === 5
                                ? `${Number(
                                    selectDayItem?.price
                                      ? selectDayItem.price
                                      : 0
                                  )
                                    .toFixed(2)
                                    .replace(
                                      ".",
                                      ","
                                    )} € + 40 % Verkaufsprovision`
                                : `${Number(
                                    selectDayItem?.price
                                      ? selectDayItem.price
                                      : 0
                                  )
                                    .toFixed(2)
                                    .replace(".", ",")} \u20AC`
                              : selectedPackage?.id === 5
                              ? `${
                                  Number(
                                    selectDayItem?.price
                                      ? selectDayItem.price
                                      : 0
                                  )
                                    .toFixed(2)
                                    .replace(".", ",") +
                                  Number(extraItem?.price ? extraItem.price : 0)
                                    .toFixed(2)
                                    .replace(".", ",")
                                } € + 40 % Verkaufsprovision`
                              : `${
                                  Number(
                                    selectDayItem?.price
                                      ? selectDayItem.price
                                      : 0
                                  )
                                    .toFixed(2)
                                    .replace(".", ",") +
                                  Number(extraItem?.price ? extraItem.price : 0)
                                    .toFixed(2)
                                    .replace(".", ",")
                                } \u20AC`
                          }`
                        )
                      : null}
                    {isCouponApplied || (isReferralCodeApplied && " €")}
                    {!isCouponApplied &&
                      !isReferralCodeApplied &&
                      `${
                        monthNames[selectedStartDate.getMonth()] ===
                          "September" ||
                        monthNames[selectedStartDate.getMonth()] === "October"
                          ? selectedPackage?.id === 5
                            ? Number(
                                selectDayItem?.price ? selectDayItem?.price : 0
                              )
                                .toFixed(2)
                                .replace(".", ",") +
                              " € + 40 % Verkaufsprovision"
                            : Number(
                                selectDayItem?.price ? selectDayItem?.price : 0
                              )
                                .toFixed(2)
                                .replace(".", ",")
                          : selectedPackage?.id === 5
                          ? (
                              Number(
                                selectDayItem?.price ? selectDayItem?.price : 0
                              ) +
                              Number(extraItem?.price ? extraItem?.price : 0)
                            )
                              .toFixed(2)
                              .replace(".", ",") + " € + 40 % Verkaufsprovision"
                          : (
                              Number(
                                selectDayItem?.price ? selectDayItem?.price : 0
                              ) +
                              Number(extraItem?.price ? extraItem?.price : 0)
                            )
                              .toFixed(2)
                              .replace(".", ",")
                      }${selectedPackage.id === 5 ? "" : " \u20AC"}`}
                  </li>
                </ul>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ boxShadow: 0 }}
            justifyContent={"center"}
          >
            <Item sx={{ boxShadow: 0 }}>
              <div>
                <div>
                  {selectedPackage.id === 5 && extraItem.id === 1 ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => sendEmail(true)}
                      style={{
                        background: "#b98b8b",
                        margin: "5px 0px",
                        width: "320px",
                        height: "60px",
                        alignSelf: "center",
                        marginBottom: "5px",
                        fontSize: "19px",
                      }}
                    >
                      Buchung abschließen
                    </Button>
                  ) : (
                    <PayPalScriptProvider
                      options={{
                        "client-id":
                          "ARni4L2au2UrJiQ5MAhZ6x1DvU45y39H5absAGCtNNwdFdY412pOlCzWr8gB9ejNQym5LsiBC0WzTvP5",
                        "disable-funding":
                          "credit,paylater,card,giropay,sepa,credit",
                        currency: "EUR",
                      }}
                    >
                      {showButton ? (
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            background: "#b98b8b",
                            margin: "5px 0px",
                            width: "320px",
                            height: "60px",
                            alignSelf: "center",
                            marginBottom: "5px",
                            fontSize: "19px",
                          }}
                        >
                          Bestellung abschließen
                        </Button>
                      ) : (
                        <PayPalButtons
                          style={{ layout: "vertical" }}
                          createOrder={createOrder}
                          onApprove={onApprove}
                        />
                      )}
                    </PayPalScriptProvider>
                  )}
                </div>
              </div>
            </Item>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CardComponent;
