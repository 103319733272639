import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import shelf2 from "../../../img/newimage.jpg";
import shelf from "../../../img/image2.jpg";
import "./style2.css";
import http from "../../../http";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";

const Shelf = ({ selectedStand, setSelectedStand, setActive }) => {
  const location = useLocation();
  const { startDate, endDate } = location.state;

  const [shelfList, setShelfList] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/stand/stand_status_by_dates?startDate=${startDate}&endDate=${endDate}`,
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        const filteredData = data.filter((d) => {
          if (Number.isNaN(parseInt(d.label))) {
            return true;
          }
          return (
            Number(d.label) !== 139 &&
            Number(d.label) !== 138 &&
            Number(d.label) !== 137 &&
            Number(d.label) !== 95 &&
            Number(d.label) !== 163 &&
            Number(d.label) !== 162 &&
            Number(d.label) !== 161 &&
            Number(d.label) !== 160 &&
            Number(d.label) !== 140 &&
            Number(d.label) !== 141 &&
            Number(d.label) !== 142 &&
            Number(d.label) !== 143 &&
            Number(d.label) !== 144 &&
            Number(d.label) !== 145 &&
            Number(d.label) !== 154 &&
            Number(d.label) !== 155 &&
            Number(d.label) !== 156 &&
            Number(d.label) !== 157 &&
            Number(d.label) !== 158 &&
            Number(d.label) !== 159 &&
            Number(d.label) !== 19 &&
            Number(d.label) !== 20 &&
            (d.label < 59 || d.label > 94)
          );
        });
        setShelfList(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const StandBox = ({
    width,
    height,
    x,
    y,
    fill,
    label,
    id,
    isAvailable,
    status,
  }) => {
    let shapeElement;
    let styleProps = {};

    shapeElement = (
      <rect
        id={`shelf_${id}`}
        data-shelf_id={`${id}`}
        data-shelf_label={`${label}`}
      />
    );

    if (fill) {
      styleProps.fill = fill;
    }
    if (width) {
      styleProps.width = width;
    }
    if (height) {
      styleProps.height = height;
    }
    if (x) {
      styleProps.x = x;
    }
    if (y) {
      styleProps.y = y;
    }

    return (
      <g
        transform="matrix(1,0,0,1,0,0)"
        className={`${
          status == "active" ? "cursor-pointer" : "cursor-no-allowed "
        } animate`}
        role="button"
        onClick={() => {
          if (isAvailable) {
            setSelectedStand({ id, label, x, y, isAvailable });
            setIsNext(true);
          } else {
            return null;
          }
        }}
      >
        {React.cloneElement(shapeElement, {
          style: {
            ...styleProps,
            fill: selectedStand?.id == id ? "#D2A98B" : fill,
          },
        })}
        {status == "active" ? (
          <text
            x={`${Number(x) + width / 2}`}
            y={`${Number(y) + 3}`}
            fill="#000"
            fontFamily="Helvetica"
            fontSize="14"
            textAnchor="middle"
            className=""
          >
            <tspan dy="18.2" x={`${Number(x) + width / 2}`}>
              {label}
            </tspan>
          </text>
        ) : (
          <text
            x={`${Number(x) + width / 2}`}
            y={`${Number(y) + 3}`}
            fill="#000"
            fontFamily="Helvetica"
            fontSize="14"
            textAnchor="middle"
            className=""
          >
            <tspan dy="18.2" x={`${Number(x) + width / 2}`}>
              {label}
            </tspan>
          </text>
        )}
        {label != "Kasse" &&
        label != "Eingang" &&
        label != "WC / Still- und Wickelraum" ? (
          <>
            <line
              x1={x}
              y1={y}
              x2={Number(x) + width}
              y2={y}
              strokeWidth="1"
              stroke="#000000"
            ></line>
            <line
              x1={x}
              y1={Number(y) + width}
              x2={Number(x) + width}
              y2={Number(y) + height}
              strokeWidth="1"
              stroke="#000000"
            ></line>
            <line
              x1={x}
              y1={y}
              x2={x}
              y2={Number(y) + height}
              strokeWidth="1"
              stroke="#000000"
            ></line>
            <line
              x1={Number(x) + width}
              y1={y}
              x2={Number(x) + width}
              y2={Number(y) + height}
              strokeWidth="1"
              stroke="#000000"
            ></line>
          </>
        ) : null}
      </g>
    );
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        style={{ width: "85%", margin: "auto" }}
        justifyContent={"center"}
      >
        <Grid
          item
          sm={10}
          md={12}
          lg={12}
          style={{ height: "65vh", overflow: "auto" }}
        >
          <div className="">
            <svg
              width="980"
              height="600"
              style={{ marginTop: -100 }}
              viewBox="0 0 980 600"
            >
              {shelfList?.length > 0 &&
                shelfList?.map((item, index) => {
                  return (
                    <StandBox
                      key={index}
                      fill={
                        item?.status == "active"
                          ? item?.is_available == 1
                            ? "#d9d9d9"
                            : "#ff8080"
                          : item?.label == "Kasse" ||
                            item?.label == "Eingang" ||
                            item?.label == "WC / Still- und Wickelraum"
                          ? "#dabcbc"
                          : "#ff8080"
                      }
                      width={item?.width}
                      height={item?.height}
                      x={item?.x}
                      y={item?.y}
                      id={item?.id}
                      label={item?.label}
                      status={item?.status}
                      isAvailable={
                        item?.is_available == 1 && item?.status == "active"
                          ? true
                          : false
                      }
                    />
                  );
                })}
            </svg>
          </div>
        </Grid>
      </Grid>
      <div style={{ width: "85%" }}>
        <Button
          variant="contained"
          disabled={!isNext}
          size="large"
          style={{
            background: isNext ? "#b98b8b" : "#7a7e80",
            color: "#fff",
            marginTop: "20px",
            float: "right",
          }}
          onClick={() => {
            setActive(3);
          }}
        >
          Weiter
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{ background: "#e1b6b4", margin: "20px", float: "right" }}
          onClick={() => {
            setActive(1);
          }}
        >
          Zurück
        </Button>
      </div>

      <Grid
        container
        spacing={4}
        style={{ width: "90%", margin: "auto" }}
        display="flex"
        justifyContent="start"
      >
        <Grid item sm={12} md={4} lg={4}>
          <div className="divs">
            <img src={shelf2} height="387px" width="287px" />
          </div>
        </Grid>
        <Grid item sm={10} md={4} lg={4}>
          <div className="divs">
            <img src={shelf} height="387px" width="287px" />
          </div>
        </Grid>

        <Grid item sm={12} md={5} lg={4}>
          <Box
            className=""
            height="400px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ color: "grey", boxShadow: 2, borderRadius: 5, p: 5 }}
          >
            <Typography style={{ fontSize: "20px" }}>Regalmaße</Typography>
            <br />
            <Typography style={{ fontSize: "20px" }}>Höhe: 213cm</Typography>
            <br />
            <Typography style={{ fontSize: "20px" }}>Breite: 60cm</Typography>
            <br />
            <Typography style={{ fontSize: "20px" }}>Tiefe: 40cm</Typography>
            <br />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Shelf;
