import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import http from "../../http";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Signup = ({
  setEmailExits,
  setUserDetail,
  setNewsOffer,
  setActive,
  selectedPackage,
  newsOffer,
  setCouponDetail,
  isCouponApplied,
  setISCouponApplied,
  referralCode,
  setReferralCode,
  setReferralDetail,
  isReferralCodeApplied,
  setIsReferralCodeApplied,
  userId,
  setUserId,
}) => {
  const [email, setemail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [lastVerifiedEmail, setLastVerifiedEmail] = useState("");
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    zip: "",
    iban: "",
    country: "",
  });

  useEffect(() => {
    setLoading(true);
    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: "/user",
      })
      .then((response) => {
        const { data } = response.data;
        setUserId(data?.id);
        setemail(data?.email);
        setUserDetails((prev) => ({ ...prev, ...data }));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userDetails.first_name || "",
      lastName: userDetails.last_name || "",
      email: userDetails.email || "",
      phoneNo: userDetails.phone_number || "",
      address: userDetails.address || "",
      country: userDetails.country || "",
      zip: userDetails.zip || "",
      iban: userDetails.iban || "",
      agreeTerms: false,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Bitte geben Sie Ihren Vornamen ein"),
      lastName: Yup.string().required("Bitte geben Sie Ihren Nachnamen ein"),
      address: Yup.string().required("Bitte geben Sie Ihre Adresse ein"),
      country: Yup.string().required("Bitte geben Sie Ihr Ort ein"),
      zip: Yup.string().required("Bitte geben Sie Ihre Postleitzahl ein"),
      iban: Yup.string()
      .required("Bitte geben Sie Ihre IBAN ein")
      .matches(/^DE(\s?\d){20}$/, "IBAN muss mit 'DE' beginnen und genau 20 Zahlen enthalten"),
      email: Yup.string().required("Bitte geben Sie Ihre E-Mail ein"),
      phoneNo: Yup.string().required("Bitte geben Sie Ihre E-Mail ein"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values.email,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/auth/is-email-already-exist",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      if (localStorage.getItem("authUser") != undefined) {
        setEmailExits(true);
        setUserDetail(values);
        setActive(4);
      } else {
        setLoading(true);

        http
          .request(config)
          .then((response) => {
            const { message } = response.data;
            if (message === "User exist with this email") {
              alert(
                "Ihre E-Mail existiert bereits. Bitte loggen Sie sich in Ihr Konto ein und versuchen Sie es erneut."
              );
              setEmailExits(true);
              setLoading(false);
              setError(true);
            } else {
              setLoading(false);
              setEmailExits(false);
              setUserDetail(values);
              setActive(4);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    },
  });

  const verifyCouponCode = () => {
    setLoading(true);
    let url;
    if (localStorage.getItem("id")) {
      url = `/couponRoute/verifyCoupon/${couponCode}/${localStorage.getItem(
        "id"
      )}`;
    } else {
      url = `/couponRoute/verifyCoupon/${couponCode}/-1`;
    }
    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url,
      })
      .then((response) => {
        const { data, message, status } = response.data;
        if (!status) {
          alert("Der Gutschein ist nicht gültig");
          setISCouponApplied(false);
          setLoading(false);
        }
        if (
          message === "This is a bonus coupon" &&
          (selectedPackage.id < 2 || selectedPackage.id > 4)
        ) {
          alert(
            "Der von dir eingegebene Code kann nur für Buchungen ab 2 Wochen eingelöst werden."
          );
          setISCouponApplied(false);
          setLoading(false);
        }
        if (data) {
          setCouponDetail(data);
          setISCouponApplied(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Der Gutschein ist nicht gültig");
        setISCouponApplied(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (email !== lastVerifiedEmail) {
      setIsReferralCodeApplied(false);
    }
  }, [email, lastVerifiedEmail]);

  const verifyReferralCode = () => {
    setLoading(true);

    if (selectedPackage.id < 2 || selectedPackage.id > 4) {
      alert(
        "Der von dir eingegebene Code kann nur für Buchungen ab 2 Wochen eingelöst werden."
      );
      setIsReferralCodeApplied(false);
      setLoading(false);
      return;
    }

    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: `/user/verifyReferralCode/${referralCode}/${email}`,
      })
      .then((response) => {
        const { data, status } = response.data;
        if (!status) {
          alert("Der Code ist ungültig");
          setIsReferralCodeApplied(false);
          setLoading(false);
        }
        if (data) {
          setReferralDetail(data);
          setIsReferralCodeApplied(true);
          setLastVerifiedEmail(email);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Der Code ist ungültig");
        setIsReferralCodeApplied(false);
        setLoading(false);
      });
  };

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
        }}
      >
        <Box
          sx={{
            alignSelf: "center",
            borderRadius: "1rem",
            width: { xs: "95%", sm: "90%", md: "80%", lg: "55%" },
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            sx={{
              backgroundColor: "#fdfcfc",
              padding: "30px",
              borderRadius: "1rem",
              width: "100%",
              marginTop: -40,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="firstName"
                >
                  Vorname
                </InputLabel>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  placeholder="Vorname"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="lastName"
                >
                  Familienname
                </InputLabel>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  // label="Last Name"
                  placeholder="Familienname"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="address"
                >
                  Adresse
                </InputLabel>
                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  placeholder="Adresse"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="country"
                >
                  Ort
                </InputLabel>
                <TextField
                  fullWidth
                  id="country"
                  name="country"
                  placeholder="Ort"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                  helperText={formik.touched.country && formik.errors.country}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="zip"
                >
                  Postleitzahl
                </InputLabel>
                <TextField
                  fullWidth
                  id="zip"
                  name="zip"
                  placeholder="Postleitzahl"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="phoneNo"
                >
                  Telefonnummer
                </InputLabel>
                <TextField
                  fullWidth
                  id="phoneNo"
                  name="phoneNo"
                  placeholder="Telefonnummer"
                  value={formik.values.phoneNo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                  }
                  helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="email"
                >
                  Email
                </InputLabel>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email"
                  disabled={userId}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setemail(e.target.value);
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "white",
                      padding: "10px",
                    },
                  }}
                />
              </Grid>

              {!userId && (
                <Grid item xs={12}>
                  <InputLabel
                    sx={{ fontWeight: 700, fontSize: "20px" }}
                    shrink
                    htmlFor="password"
                  >
                    Passwort
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="password"
                    name="We will send you the secure  passoword by email"
                    label="Wir senden Ihnen das Passwort zu"
                    placeholder="Wir senden Ihnen das Passwort zu"
                    type="number"
                    disabled
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "white",
                        padding: "10px",
                      },
                    }}
                    helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="iban"
                >
                  *IBAN
                </InputLabel>
                <InputMask
                  mask="DE99 9999 9999 9999 9999 99"
                  maskChar="_"
                  value={formik.values.iban}
                  onChange={formik.handleChange}
                >
                  {() => (
                    <TextField
                      fullWidth
                      id="iban"
                      name="iban"
                      label=""
                      placeholder="IBAN"
                      value={formik.values.iban}
                      onChange={formik.handleChange}
                      error={formik.touched.iban && Boolean(formik.errors.iban)}
                      helperText={formik.touched.iban && formik.errors.iban}
                      sx={{
                        "& label": {
                          color: "black",
                        },
                        "& input": {
                          backgroundColor: "white",
                          padding: "10px",
                        },
                      }}
                    />
                  )}
                </InputMask>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                    shrink
                    htmlFor="Gutschein"
                  >
                    Gutschein
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="Gutschein"
                    name="Gutschein"
                    placeholder="Gutschein"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "white",
                        padding: "10px",
                      },
                    }}
                    helperText={isCouponApplied && "Gutschein bestätigt"}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#b98b8b",
                      margin: "5px 0px",
                      marginBottom: "12px",
                    }}
                    onClick={() => {
                      verifyCouponCode();
                    }}
                  >
                    verifizieren
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                    shrink
                    htmlFor="referralCode"
                  >
                    Empfehlungs-Code
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="referralCode"
                    name="referralCode"
                    placeholder="Empfehlungs-Code"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "white",
                        padding: "10px",
                      },
                    }}
                    helperText={
                      isReferralCodeApplied && email === lastVerifiedEmail
                        ? "Empfehlungs-Code bestätigt"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#b98b8b",
                      margin: "5px 0px",
                      marginBottom: "12px",
                    }}
                    onClick={verifyReferralCode}
                    disabled={!email || !referralCode}
                  >
                    verifizieren
                  </Button>
                </Grid>

                <span>
                  *Wir benötigen deine IBAN für die Auszahlung deines Guthabens
                  am Ende deiner Mietlaufzeit.
                </span>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked1}
                      onChange={() => setChecked1(!checked1)}
                    />
                  }
                  sx={{ width: "100%", marginTop: "5px" }}
                  label={
                    <span>
                      Ich stimme den{" "}
                      <a href="/letter">Allgemeinen Geschäftsbedingungen</a> von
                      minmini zu.
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked2}
                      onChange={() => setChecked2(!checked2)}
                    />
                  }
                  sx={{ width: "100%", marginTop: "5px" }}
                  label={
                    <span>
                      Ich habe die{" "}
                      <a href="/datenschutz">Datenschutzinformationen</a> von
                      minmini zur Kenntnis genommen. Ein Widerruf der Zustimmung
                      ist jederzeit möglich.
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newsOffer}
                      onChange={() => setNewsOffer((prev) => !prev)}
                    />
                  }
                  sx={{ width: "100%", marginTop: "5px" }}
                  label={
                    <span>
                      Ja, ich möchte Neuigkeiten über Angebote und Updates von
                      minmini erhalten. Ein Widerruf der Zustimmung ist
                      jederzeit möglich.
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked4}
                      onChange={() => setChecked4(!checked4)}
                    />
                  }
                  sx={{ width: "100%", marginTop: "5px" }}
                  label={
                    <span>
                      Ich akzeptiere die{" "}
                      <a href="/urban">Widerrufsbelehrung.</a>
                    </span>
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "80%",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          style={{
            marginRight: "8px",
            background: "#b98b8b",
            marginRight: "37px",
          }}
          onClick={() => {
            setActive(2);
          }}
        >
          Zurück
        </Button>
        <Button
          disabled={!checked1 || !checked2 || !checked4}
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
          style={{ marginRight: "8px", background: "#b98b8b" }}
        >
          Weiter
        </Button>
      </Box>
    </>
  );
};

export default Signup;
